import styles from "./styles.module.css";

import girl from "../../assets/illustrations/girl.svg";
import kantacard from "../../assets/illustrations/kantacard.svg";

export default function WhitePaper() {
  return (
    <section className={styles.whitePaper}>
      <div className={styles.info}>
        <p>
          Simplifying cryptocurrency fiat settlement and spending solution to
          attract the
          <br />
          next <span>billion users</span>.
        </p>
      </div>
      <div className={styles.content}>
        <div className={styles.left}>
          <h1>
            One-stop solution for
            <br />
            seamless <span>Crypto-To-Fiat</span>
          </h1>
          <p>
            <span>Kanta</span> is designed to address the complexity
            <br />
            behind converting & spending digital assests
            <br /> as you like by providing best-in-class automated
            <br />
            settlement platform powered by blockchain
            <br />
            technology.
          </p>
          <button>Whitepaper</button>
        </div>
        <div className={styles.right}>
          <img src={girl} alt="power illustration" className={styles.girl} />
          <img src={kantacard} alt="Kanta Card" className={styles.kantacard} />
        </div>
      </div>
    </section>
  );
}
