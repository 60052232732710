import styles from "./styles.module.css";

//buttons
import googleplay from "../../assets/buttons/googleplaybadge.svg";
import appstore from "../../assets/buttons/appstorebadge.svg";

// team members
import op from "../../assets/images/op.png";
import mus from "../../assets/images/mus.png";
import mat from "../../assets/images/mat.png";

// advisors
import ak from "../../assets/images/ak.png";

//mockups
import greeting from "../../assets/mockups/greeting.png";
import greeting2 from "../../assets/mockups/greeting2.png";

//illustrations
import polygon7 from "../../assets/illustrations/Polygon7.svg";
import circle7 from "../../assets/illustrations/circle7.svg"
import circle8 from "../../assets/illustrations/circle8.svg"
import circle9 from "../../assets/illustrations/circle9.svg"
import blur1 from "../../assets/illustrations/blur1.svg"

export default function Team() {
  return (
    <section className={styles.team}>
      <h1>Meet the Team</h1>
      <div className={styles.teamMembers}>
        <img src={op} alt="op" />
        <img src={mus} alt="mus" />
        <img src={mat} alt="mat" />
      </div>
      <h1>Advisors</h1>
      <div className={styles.teamAdvisors}>
        <img src={ak} alt="ak" />
      </div>
      <div className={styles.getApp}>
        <div className={styles.left}>
          <h1>
            The Exchange app
            <br />
            for everyone{" "}
          </h1>
          <div className={styles.buttons}>
            <button className={styles.button1}>
              <img src={googleplay} alt="Google Play" />
            </button>
            <button className={styles.button2}>
              <img src={appstore} alt="App Store" />
            </button>
            <p>Coming Soon</p>
          </div>
        </div>
        <div className={styles.right}>
          <img src={greeting} alt="greeting" className={styles.greeting} />
          <img src={greeting2} alt="greeting2" className={styles.greeting2} />
        </div>
      </div>
        <div className={styles.polygon7}>
            <img src={polygon7} alt="polygon7" />
        </div>
        <div className={styles.circle7}>
            <img src={circle7} alt="circle7" />
        </div>
        <div className={styles.circle8}>
            <img src={circle8} alt="circle8" />
        </div>
        <div className={styles.circle9}>
            <img src={circle9} alt="circle9" />
        </div>
        <div className={styles.blur1}>
            <img src={blur1} alt="blur1" />
        </div>
    </section>
  );
}
