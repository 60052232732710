import styles from "./styles.module.css";

import intro from "../../assets/mockups/intro.png";

export default function Business() {
  return (
    <section className={styles.business}>
      <div className={styles.left}>
        <h1>
          Simple crypto-to-fiat
          <br />
          solutions to power your
          <br />
          business
        </h1>
        <p>
          implement crypto into your existing workflow
          <br />
          by integrating kanta payment solutions with
          <br />
          traditional finance, enable retail and business
          <br />
          clients to automatically convert crypto-to-fiat.
        </p>
        <p className={styles.note}>
          No more unfamiliar money senders
          <br />
          or recipients on bank statements
        </p>
        <div className={styles.buttons}>
          <button>Explore More</button>
          <p>Built on a solid foundation</p>
        </div>
      </div>
      <div className={styles.right}>
        <img src={intro} alt="intro" />
      </div>
    </section>
  );
}
