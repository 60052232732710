import styles from "./styles.module.css";

import fluid from "../../assets/logo/fluid.svg";
import obtuse from "../../assets/logo/obtuse.svg";
import cardano from "../../assets/logo/cardano.svg";

import rect1 from "../../assets/illustrations/rect1.svg";

export default function GlobalPartners() {
  return (
    <section className={styles.globalPartners}>
      <h1>Global Partners</h1>
      <div className={styles.partners}>
        <a
          href="
        https://fluidtokens.com/"
          target="_blank"
          rel="noreferrer
        "
        >
          <img src={fluid} alt="Fluid" />
        </a>
        <a href="https://otechbank.com/" target="_blank" rel="noreferrer">
          <img src={obtuse} alt="Obtuse" />
        </a>
        <a href="https://cardano.org/" target="_blank" rel="noreferrer">
          <img src={cardano} alt="Cardano" />
        </a>
      </div>
      <div className={styles.rect1}>
        <img src={rect1} alt="Rect1" />
      </div>
    </section>
  );
}
