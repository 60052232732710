import styles from "./styles.module.css";

import kantalogo from "../../assets/logo/kantalogo.svg";

import fb from "../../assets/icons/Fb.svg";
import tw from "../../assets/icons/Tw.svg";
import ig from "../../assets/icons/Ig.svg";
import ln from "../../assets/icons/Ln.svg";

import mail from "../../assets/icons/mail.svg";

//illustrations
import polygon8 from "../../assets/illustrations/polygon8.svg";
import polygon9 from "../../assets/illustrations/polygon9.svg";

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <h1>Subscribe to our newsletter</h1>
      <p>
        Never miss a beat.
        <br />
        Get a weekly dose of exchange update, news,
        <br />
        secrets, tips, trends and in your inbox.
      </p>
      <form>
        <div className={styles.input
        }>
          <img src={mail} alt="Mail" />
          <input type="email" placeholder="Email address" />
        </div>
        <button>Subscribe</button>
      </form>
      <div className={styles.content}>
        <div className={styles.info1}>
          <div className={styles.logo}>
            <img src={kantalogo} alt="Logo" />
          </div>
          <p>
            Convert and spend digital asset with
            <br />
            ease best-in-class automated
            <br />
            settlement platform powered by
            <br />
            blockchain technology.
          </p>
          <div className={styles.socials}>
            <img src={fb} alt="Facebook" />
            <img src={tw} alt="Twitter" />
            <img src={ig} alt="Instagram" />
            <img src={ln} alt="LinkedIn" />
          </div>
        </div>
        <div className={styles.info2}>
          <p>Mass Payments</p>
          <p>Debit Card</p>
          <p>Assets</p>
        </div>
        <div className={styles.info3}>
          <p>Financial Services</p>
          <p>Fashion & Beauty</p>
          <p>Agriculture</p>
        </div>
        <div className={styles.info4}>
          <p>Help center</p>
          <p>FAQ</p>
          <p>Contact</p>
          <p>Press</p>
        </div>
      </div>
      <div className={styles.polygon8}>
        <img src={polygon8} alt="polygon8" />
      </div>
      <div className={styles.polygon9}>
        <img src={polygon9} alt="polygon9" />
      </div>
    </footer>
  );
}
