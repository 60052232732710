import styles from "./styles.module.css";

import exchange2 from "../../assets/mockups/exchange2.png";
import wallet from "../../assets/icons/wallet.svg";
import exchange from "../../assets/icons/exchange.svg";
import chart from "../../assets/icons/chart.svg";
import card from "../../assets/icons/card.svg";

import polygon1 from "../../assets/illustrations/polygon1.svg";
import polygon2 from "../../assets/illustrations/polygon2.svg";
import polygon3 from "../../assets/illustrations/polygon3.svg";
import polygon4 from "../../assets/illustrations/polygon4.svg";

function Tab({ title, icon, active, onClick }) {
  return (
    <button
      className={styles.tab}
      style={{
        background: active
          ? "linear-gradient(180deg, #c9e8e8 0%, #b2b2b2 100%)"
          : "transparent",
      }}
      onClick={onClick}
    >
      <div className={styles.icon}>{icon}</div>
      <p className={active ? styles.active : ""}>{title}</p>
    </button>
  );
}

export default function Product() {
  return (
    <div className={styles.container}>
      <h1>
        Enjoy the optimal blend of
        <br />
        Web2 & Web3
      </h1>
      <p>
        We emerged as a soluton to simplify the crypto-to-fiat settlement while
        contributing to traditional
        <br />
        finance economic growth by fostering a more fluid and accessible
        financial landscape across the
        <br />
        African market.
      </p>
      <div className={styles.content}>
        <div className={styles.left}>
          <Tab
            title="In App Wallet"
            icon={<img src={wallet} alt="In App Wallet" />}
            active
          />
          <Tab title="Exchange" icon={<img src={exchange} alt="Exchange" />} />
          <Tab title="Spending" icon={<img src={chart} alt="Spending" />} />
          <Tab title="Virtual Card" icon={<img src={card} alt="card" />} />
        </div>
        <div className={styles.right}>
          <img src={exchange2} alt="exchange" />
          <p>
            Designed to ensure simple asset management
            <br />& maximize asset utilization while offering
            <br /> a flawless experience. Store, send, and
            <br />
            receive digital currencies.
          </p>
        </div>
      </div>
      <div className={styles.polygon1}>
        <img src={polygon1} alt="Polygon1" />
      </div>
      <div className={styles.polygon2}>
        <img src={polygon2} alt="Polygon2" />
      </div>
      <div className={styles.polygon3}>
        <img src={polygon3} alt="Polygon3" />
      </div>
      <div className={styles.polygon4}>
        <img src={polygon4} alt="Polygon4" />
      </div>
    </div>
  );
}
