import styles from "./styles.module.css";

import scan from "../../assets/icons/scan.svg";
import dapp from "../../assets/icons/dapp.svg";
import cart from "../../assets/icons/cart.svg";

import service from "../../assets/images/service.png";

import design1 from "../../assets/illustrations/design1.svg";

function SlideIndicator({ active }) {
  return (
    <div className={styles.slideIndicator}>
      <div className={active ? styles.active : ""}></div>
    </div>
  );
}

function Summary({ icon, title }) {
  return (
    <div className={styles.summary}>
      <img src={icon} alt={title} />
      <p>{title}</p>
    </div>
  );
}

function Detail({ image, title, description }) {
  return (
    <div className={styles.detail}>
      <img src={image} alt={title} />
      <h1>{title}</h1>
      <p>{description}</p>
    </div>
  );
}

export default function Services() {
  return (
    <section className={styles.services}>
      <div className={styles.illustration}>
        <img src={design1} alt="design" />
      </div>
      <h1>Services</h1>
      <div className={styles.servicesSummary}>
        <Summary icon={scan} title="Scan to Pay" />
        <Summary icon={dapp} title="Dapp Support" />
        <Summary icon={cart} title="In App Store" />
      </div>
      <div className={styles.servicesDetails}>
        <Detail
          image={service}
          title={"DAPP Support"}
          description={
            "Offering access to financial products to unbanked and underbanked users in Nigeria and across Africa market, while bridging the gap between Web2 & Web3."
          }
        />
        <Detail
          image={service}
          title={"Scan to Pay"}
          description={
            "Facilitates swift, & secure payment transactions, to effortlessly utilize cryptocurrency while enabling businesses to receive payments in either fiat or stablecoins."
          }
        />
        <Detail
          image={service}
          title={"In App Store"}
          description={
            "Enjoy the convenience of spending digital assets with KANTA. Services like Bill Payment, Airtime Top-Up, Betting, Data Bundles, and an ever-expanding list of other services."
          }
        />
      </div>
      <div className={styles.slideIndicators}>
        {[1, 2, 3, 4].map((index) => (
          <SlideIndicator key={index} active={index === 1} />
        ))}
      </div>
    </section>
  );
}
