import styles from "./styles.module.css";

import feature from "../../assets/icons/chart2.svg";
import btc from "../../assets/icons/btc.svg";
import eth from "../../assets/icons/eth.svg";
import bat from "../../assets/icons/bat.svg";

import settlement from "../../assets/mockups/settlement.png";

import polygon5 from "../../assets/illustrations/polygon5.svg";
import polygon6 from "../../assets/illustrations/polygon6 .svg";

function Feature({ title, icon }) {
  return (
    <div className={styles.feature}>
      <div className={styles.icon}>{icon}</div>
      <p>{title}</p>
    </div>
  );
}

export default function Features() {
  return (
    <section className={styles.features}>
      <div className={styles.bat}>
        <img src={bat} alt="bat" />
      </div>
      <div className={styles.eth}>
        <img src={eth} alt="eth" />
      </div>
      <div className={styles.btc}>
        <img src={btc} alt="btc" />
      </div>
      <div className={styles.left}>
        <h1>Built on trust</h1>
        <p>
          We offers a secure, transparent, and
          <br />
          holistic approach to our products
        </p>
        <div className={styles.featureContainer}>
          <Feature
            title="Support For Wallet Connet"
            icon={<img src={feature} alt="Support" />}
          />
          <Feature
            title="Access To Defi"
            icon={<img src={feature} alt="Access" />}
          />
          <Feature
            title="Automated OTC Desk"
            icon={<img src={feature} alt="Automated" />}
          />
          <Feature
            title="Crypto Payment Gateway"
            icon={<img src={feature} alt="Crypto" />}
          />
        </div>
      </div>
      <div className={styles.middle}>
        <img src={settlement} alt="settlement" />
      </div>
      <div className={styles.right}>
        <div className={styles.top}>
          <h1>Key Features</h1>
          <p>
            Support a wide range of financial
            <br />
            services
          </p>
        </div>
        <div className={styles.bottom}>
          <h1>
            Your Crypto,
            <br />
            Your Choice
          </h1>
          <p>
            Verify crypto-to-fiat transactions directly
            <br />
            from your web3 wallet by connecting your
            <br />
            wallet or initiating a manual transfer
          </p>
          <p>
            Kanta establishes a secure encrypted
            <br />
            connection to your Web3 and
            <br />
            doesn't have access to your private keys
          </p>
        </div>
      </div>
      <div className={styles.polygon5}>
        <img src={polygon5} alt="polygon5" />
      </div>
      <div className={styles.polygon6}>
        <img src={polygon6} alt="polygon6" />
      </div>
    </section>
  );
}
