import styles from "./styles.module.css";

import exchange from "../../assets/mockups/exchange.png";
import rate from "../../assets/mockups/rates.svg";
import googleplay from "../../assets/buttons/googleplaybadge.svg";
import appstore from "../../assets/buttons/appstorebadge.svg";
import circle1 from "../../assets/illustrations/circle1.svg";

export default function Section1() {
  return (
    <section className={styles.section1}>
      <div className={styles.containerLeft}>
        <img src={exchange} alt="exchange" className={styles.exchange} />
        <img src={rate} alt="rate" className={styles.rate} />
      </div>
      <div className={styles.containerRight}>
        <div className={styles.circle1}>
          <img src={circle1} alt="circle1" />
        </div>
        <div>
          <h1>
            Embrace the digital
            <br />
            currency era with <span>Kanta</span>
          </h1>
          <p>
            Where individuals and businesses can
            <br />
            effortlessly convert or spend
            <br />
            cryptocurrencies like local fiat.
          </p>
        </div>
        <div className={styles.buttons}>
          <button className={styles.button1}>
            <img src={googleplay} alt="Google Play" />
          </button>
          <button className={styles.button2}>
            <img src={appstore} alt="App Store" />
          </button>
          <p>Coming Soon</p>
        </div>
      </div>
    </section>
  );
}
