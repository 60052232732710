import styles from "./styles.module.css";

import Header from "../../components/Header/Header";
import Section1 from "../../components/Section1/Section1";
import GlobalPartners from "../../components/GlobalPartners/GlobalPartners";
import WhitePaper from "../../components/WhitePaper/WhitePaper";
import Product from "../../components/Product/Product";
import Features from "../../components/Features/Features";
import Business from "../../components/Business/Business";
import Services from "../../components/Services/Services";
import Team from "../../components/Team/Team";
import Footer from "../../components/Footer/Footer";

export default function Home() {
  return (
    <div className={styles.container}>
      <Header />
      <Section1 />
      <GlobalPartners />
      <WhitePaper />
      <Product />
      <Features />
      <Business />
      <Services />
      <Team />
      <Footer />
    </div>
  );
}
