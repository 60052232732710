import React from "react";

import styles from "./styles.module.css";

import { SlArrowDown } from "react-icons/sl";

import kantalogo from "../../assets/logo/kantalogo.svg";

import menu from "../../assets/icons/Menu Hamburger.svg";
import close from "../../assets/icons/close.svg";

export default function Header() {
  const [showMobileNav, setShowMobileNav] = React.useState(false);

  const toggleMobileNav = () => {
    setShowMobileNav(!showMobileNav);
  };



  return (
    <header className={styles.header}>
      <div className={styles.logo}>
        <img src={kantalogo} alt="Logo" />
      </div>
      <nav className={styles.nav}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <p>Product</p>
          <SlArrowDown
            style={{
              color: "#5E9300",
              fontSize: "1.5rem",
            }}
          />
        </div>
        <p>About</p>
        <p>Services</p>
        <p>Blog & updaes</p>
        <button className={styles.button}>GET IN TOUCH</button>
      </nav>
      <button className={styles.menu} onClick={toggleMobileNav}>
        <img src={menu} alt="Menu" />
      </button>
      <div
        className={styles.mobileNav}
        style={{
          display: showMobileNav ? "block" : "none",
        }}
      >
        <p>Product</p>
        <p>About</p>
        <p>Services</p>
        <p>Blog & updates</p>
        <button className={styles.buttonMobile}>GET IN TOUCH</button>
        <button className={styles.close} onClick={toggleMobileNav}>
          <img src={close} alt="Close" />
        </button>
      </div>
    </header>
  );
}
